
const tableInfoMessages={
all:" ",
affiliates:"Table has a unique affiliate_id. The scheduler for this table runs daily at 6:15 AM EST time",
customer:"Table has a unique customer_number.The scheduler for this table runs daily at 5:55 AM EST time",
offers:"Table has a unique offer_id.The scheduler for this table runs daily at 6:20 AM EST time",
product:"Table has a unique product_number.The scheduler for this table runs daily at 5:45 AM EST time",
customcpa:"Table has a uniuqe custom_cpa_id.The scheduler for this table runs daily at 6:30 AM EST time",
woworder:"Table has a unique combindation of order_number,transaction_id.The scheduler runs twice at 9:50 AM and 7:10 PM EST time",
order:"Table has a unique order_number.The scheduler for this table runs daily every 4 hour",
cardknox:"Table has a unique reference_number.The scheduler for this table runs daily at 5:50 AM EST time",
everflow:"Table has a unique transaction_id,type,event_name.The scheduler for this table runs daily at 6:25 AM EST time",
paypal:"Table has a unique combination of transaction_id,description,transaction_event_code.The scheduler for this table runs daily at 6:05 AM EST time",
shipstation:"Table has a unique combindation of order_number,customer_email,operations_batch_number,ship_to_name,shipment_height,shipment_length.The scheduler for this table runs daily at 6:10 AM EST time",
"wow_conversions":"Table has a unique conversion_id.The scheduler for this table runs daily at 6:40 AM EST time",
"wow-campaigns":"Table has a unique campaign_id.The scheduler for this table runs daily at 6:45 AM EST time",
"shipstation_products":"Table has unique sku.The scheduler for this table runs daily at 6:35 AM EST time",
user:"Table has a unique email",
saleschannel:"Table has a unique sales_channel_id.The scheduler for this table runs daily at 6 AM EST time",
chargebacks:"Table has a unique case_number."
}


module.exports.tableInfoMessages=tableInfoMessages;
