
import React, { Component, Fragment, useEffect,useState,useRef } from 'react';
// import React, { useState, useEffect, useMemo, useRef } from 'react';
// import NavBar from '../NavBar/NavBar';
import './Home.css'
import { useHistory } from "react-router-dom";
import $ from 'jquery';
import Sidebar from '../Template/Sidebar/Sidebar';
import Header from '../Template/Header/Header';
import DatePicker from "react-datepicker";
import moment from 'moment'
import CloseIcon from '@mui/icons-material/Close';
import "react-datepicker/dist/react-datepicker.css";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Slider, { SliderThumb, SliderValueLabelProps } from '@mui/material/Slider';
import { formatNumberWithToFixed, roundToFour, roundToTwo, inArrayForString } from '../../components/reports/ProfitabilityDiagnosis/common/commanFn';
import { baseUrl } from '../../config';
import axios from 'axios';
import Loader from 'react-loader-spinner';
// window.jquery = window.$ = $


function HomePage(props) {
  const history = useHistory();
  var i=0;
  const userdata=JSON.parse(localStorage.getItem('userdata'));
  const homeUserName=userdata?.username;
  const handleSignOut = () => {
    console.log('logout')
    window.localStorage.setItem('isLogin',false);
    history.push('/');
  }

const [welcomeMsg, setWelcomeMsg] = useState(`Welcome ${homeUserName} to Clearer Data`); 
 
if(localStorage.getItem('isLogin')==="false" || localStorage.getItem('isLogin')==null){
    history.push('/');
}
    
  const [REFCB, setREFCB] = useState(true);
  const [Refund, setRefund] = useState('9');
  const [CB, setCB] = useState('1');
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 30)));useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  
  const [endDate, setEndDate] = useState(new Date(Date.now()).setHours(23, 59, 59, 0));
  const REFCBCheckBox = useRef();
  const [loader, setLoader] = useState(false)
  const [message, setMessage] = useState('')
  const [reportData, setreportData] = useState([])
  const [profitData, setProfitData] = useState([])
  const [declineData, setDeclineData] = useState([])
  const fetchApi = async () => {


    if (startDate == null) {
      setMessage('Start Date must not be blank')
      return false;
    } if (endDate == null) {
      setMessage('End Date must not be blank')
      return false;
    } else {
      setMessage('')
    }

  
    let API_URL = '';
    setLoader(true);
    API_URL = baseUrl + `/reports/home/` + '?startdate=' + moment(startDate).format('YYYY-MM-DD HH:mm') + "&enddate=" + moment(endDate).format('YYYY-MM-DD HH:mm:ss') + "&refund=" + Refund + "&cb=" + CB + "&refcb=" + REFCB
    
    try {
      const response = await axios.get(API_URL, {});
      
      if (response.data.result.profitview.length > 0) {
        response.data.result.profitview.forEach((i, j) => {
          i.total_revenue = "$" + formatNumberWithToFixed(i.total_revenue).toLocaleString();
          i.AOV = "$" + formatNumberWithToFixed(i.AOV).toLocaleString();
          i.cpa_cost = "$" + formatNumberWithToFixed(i.cpa_cost).toLocaleString();
          i.cpa_cost_per_order = "$" + formatNumberWithToFixed(i.cpa_cost_per_order).toLocaleString();
          i.cogs = "$" + formatNumberWithToFixed(i.cogs).toLocaleString();
          i.cogs_per_order = "$" + formatNumberWithToFixed(i.cogs_per_order).toLocaleString();
          i.refund_dollars_amount = "$" + formatNumberWithToFixed(i.refund_dollars_amount).toLocaleString();
          i.chargebacks_dollars_amount = "$" + formatNumberWithToFixed(i.chargebacks_dollars_amount).toLocaleString();
          i.net_profit = "$" + formatNumberWithToFixed(i.net_profit).toLocaleString();
          i.shipping_cost = "$" + formatNumberWithToFixed(i.shipping_cost).toLocaleString();
          i.shipping_cost_per_order = "$" + formatNumberWithToFixed(i.shipping_cost_per_order).toLocaleString();
          i.profit_margin = roundToTwo(i.profit_margin * 100) + "%";
          i.chargebacks_dollars_percent = roundToTwo(i.chargebacks_dollars_percent * 100) + "%";
          i.chargebacks_rate_percent = roundToTwo(i.chargebacks_rate_percent * 100) + "%";
          i.refund_dollars_percent = roundToTwo(i.refund_dollars_percent * 100) + "%";
          i.refund_rate_percent = roundToTwo(i.refund_rate_percent * 100) + "%";
        });
        setreportData(response.data.result);
        setProfitData(response.data.result.profitview)
        setDeclineData(response.data.result.decline)
        setLoader(false)
      }else{
        setreportData([]);
        setLoader(false)
      }
    } catch (e) {console.log(e.message)
      setLoader(false)
      setMessage('Something went wrong');
    }
  }

  useEffect(() => {
    fetchApi();
  }, [])

  const PrettoSlider = styled(Slider)({
    color: '#337ab7',
    height: 8,
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-thumb': {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&:before': {
        display: 'none',
      },
    },
    '& .MuiSlider-valueLabel': {
      lineHeight: 1.2,
      fontSize: 10,
      background: 'unset',
      padding: 0,
      width: 25,
      height: 25,
      zIndex: -10,
      borderRadius: '50% 50% 50% 0',
      backgroundColor: '#337ab7',
      transformOrigin: 'bottom left',
      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
      '&:before': { display: 'none' },
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      },
      '& > *': {
        transform: 'rotate(45deg)',
      },
    },
  });
  return (
    <div id="wrapper" className>
      <Sidebar/>
      <Header handleSignOut={handleSignOut} welcomeMsg={welcomeMsg}/>
      <section id="content-wrapper">
      <div className="row bs-linebreak">
      <div className="col-lg-16">
      <div className="col-sm-3"></div>
      <div className="col-sm-10">
        <label className='homeReportTitle'>Summary Report for Sales Performance</label>
        </div>
        <div className="col-sm-3"></div>
        </div>
      </div>


      <div className="row bs-linebreak"></div>


      <div className="row">
        
      <div className="col-lg-16">
      {/* <div class="col-sm-2" ><b>Summary Report for Sales Performance</b></div> */}
      <div class="col-sm-10" >
      
      <div className='FilterDivSection'>
          <label className='startDateStyle_home'>Start Date</label>
          <label className='endDateStyle_home'>End Date</label>
          {!REFCB ? '' :
            <label className='refundStyle_home'>Refund Percent</label>
          }
          {!REFCB ? '' :
            <label className='chargebackStyle_home'>CB Percent</label>
          }

          <div className="input-bar">
         
            
            <div className="input-bar-item width100">

              <DatePicker
                dateFormat="yyyy-MM-dd HH:mm:ss"
                showTimeSelect
                timeFormat="p"
                timeIntervals={15}
                selected={startDate}
                className='width122x'
                onChange={(date) => setStartDate(date)}
                placeholder='Start Date'
              />

            </div>

            &nbsp;&nbsp;&nbsp;

            <div className="input-bar-item width100">

              <DatePicker
                dateFormat="yyyy-MM-dd HH:mm:ss"
                showTimeSelect
                timeFormat="p"
                timeIntervals={15}
                selected={endDate}
                className='width122x'
                onChange={(date) => setEndDate(date)}
                placeholder='End Date'
              />

            </div>
            &nbsp;&nbsp;&nbsp;

            <div className="checkboxDiv">
              <FormGroup>
                <FormControlLabel control={<Checkbox
                  ref={REFCBCheckBox}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 40 } }}
                  name='Estimate Refunds/CBs'
                  value={REFCB}
                  defaultChecked={REFCB}
                  onChange={() => setREFCB(!REFCB)}
                />}
                  label="Estimate Refunds/CBs" />
              </FormGroup>
            </div>

            {!REFCB ? '' :

              <div className="input-bar-item">


                <Box width={150}></Box>

                <PrettoSlider

                  aria-label="pretto slider"
                  defaultValue={0}
                  min={0}
                  max={15}
                  value={Refund}
                  onChange={e => setRefund(e.target.value)}
                  step={1}
                  valueLabelDisplay="on"
                  marks
                />
              </div>
            }

            {!REFCB ? '' :

              <div className="input-bar-item">



                {/* <Box sx={{ m: 3 }} /> */}
                <Box width={150}></Box>
                <PrettoSlider

                  aria-label="pretto slider"
                  defaultValue={0}
                  min={0}
                  max={15}
                  value={CB}
                  onChange={e => setCB(e.target.value)}
                  step={1}
                  valueLabelDisplay="on"
                  marks
                />
              </div>
            }

            <div className="input-bar-item">
              <button type="button" onClick={fetchApi} className="btn btn-primary">Run Report</button>

            </div>
  
          </div>

      </div>
      <div class="col-sm-4" ></div>
      </div>
      <div class="col-sm-4" ></div>
      </div>
      </div>

      {loader ?
          <div className='loaderDiv'>
            <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" /></div> :  
        <div className="row reportMainHome">
          <div className="col-lg-16 homeReportRow header">
            <div class="col-sm-2" ><span className='mr30'>Orders</span></div>
            <div class="col-sm-2" >Revenue</div>
            <div class="col-sm-2" >AOV</div>
            <div class="col-sm-2" >Profits</div>
            <div class="col-sm-2" >Margin</div>
            <div class="col-sm-2" >CPA.Total</div>


          </div>

          <div className="row">
            <div className="col-lg-12 homeReportRow">
              <div class="col-sm-2" ><span className='mr30'>{profitData[0]?.total_order}</span></div>
              <div class="col-sm-2" >{profitData[0]?.total_revenue}</div>
              <div class="col-sm-2" >{profitData[0]?.AOV}</div>
              <div class="col-sm-2" >{profitData[0]?.net_profit}</div>
              <div class="col-sm-2" >{profitData[0]?.profit_margin}</div>
              <div class="col-sm-2" >{profitData[0]?.cpa_cost}</div>
            </div>

            <div className="row">
              <div className="col-lg-12 homeReportRow header">
                <div class="col-sm-2" >COGS.Total</div>
                <div class="col-sm-2" >Shipping.Total</div>
                <div class="col-sm-2" >Refunds.Count</div>
                <div class="col-sm-2" >Refunds.Total</div>
                <div class="col-sm-2" >Chargebacks.Count</div>
                <div class="col-sm-2" >Chargebacks.Total</div>
              </div>

            </div>

            <div className="row">
              <div className="col-lg-12 homeReportRow">
                <div class="col-sm-2" >{profitData[0]?.cogs}</div>
                <div class="col-sm-2" >{profitData[0]?.shipping_cost}</div>
                <div class="col-sm-2" >{profitData[0]?.number_of_refunds}</div>
                <div class="col-sm-2" >{profitData[0]?.refund_dollars_amount}</div>
                <div class="col-sm-2" >{profitData[0]?.number_of_chargebacks}</div>
                <div class="col-sm-2" >{profitData[0]?.chargebacks_dollars_amount}</div>
              </div>
            </div>
            
            <div className='row'>
              <div className="col-lg-12 homeReportRow alignRight header">
              <div class="col-sm-2" ></div>
                <div class="col-sm-2" >Declines.Count.Cardknox</div>
                
                <div class="col-sm-2" >Declines.Rate.Cardknox</div>
              </div>

              <div className='row'>
                <div className="col-lg-12 homeReportRow">
                <div class="col-sm-2" ></div>
                  <div class="col-sm-2 " >{declineData?.count}</div>
                  
                  <div class="col-sm-2" >{declineData?.approval_rate}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        }
      </section>
    </div>
  );


}
export default HomePage;