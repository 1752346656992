
const syncMessage={

all:"Sync Button requests all records, entire table will be updated",
affiliates:"Sync Button requests all records, entire table will be updated",
customer:"Sync Button requests all records, entire table will be updated",
offers:"Sync Button requests all records, entire table will be updated",
product:"Sync Button requests all records, entire table will be updated",
customcpa:"Sync Button requests all records, entire table will be updated",
woworder:"Adds the last 2 days of Wow Orders.",
order:"Adds the last 3 days of Orders",
cardknox:"Adds the last 10 days of data",
everflow:"Adds the last 10 days of data",
paypal:"Adds the last 3 days of data",
shipstation:"Adds the last 3 days of data",
"wow_conversions":"Adds the last 2 days of data",
"wow-campaigns":"Sync Button requests all records, entire table will be updated",
"shipstation_products":"Sync Button requests all records, entire table will be updated",

}


module.exports.syncMessage=syncMessage;
